import React, { useState } from "react";
import Papa from "papaparse";
import axios from "axios";

function App() {
  const [locations, setLocations] = useState([]);
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const perPage = 10;

  const handleLogin = async () => {
    try {
      await axios.post("/api/login");
      alert("Logged in successfully!");
    } catch (error) {
      alert("Login failed: " + error.message);
    }
  };
  
  const handleDeselectAll = () => {
    setSelected([]);
  };  

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    Papa.parse(file, {
      skipEmptyLines: true,
      complete: (results) => {
        const data = results.data;
  
        // Extract the 3rd column from each row (column index 2)
        const thirdColumn = data
          .map((row) => row[2])       // Get the 3rd column
          .filter((cell) => !!cell);  // Remove empty cells
  
        setLocations(thirdColumn);
      },
    });
  };
  

  const toggleSelect = (loc) => {
    setSelected((prev) =>
      prev.includes(loc) ? prev.filter((l) => l !== loc) : [...prev, loc]
    );
  };

  const filtered = locations.filter((loc) =>
    loc.toLowerCase().includes(search.toLowerCase())
  );

  const paginated = filtered.slice(page * perPage, (page + 1) * perPage);

  const handleShip = async () => {
    try {
      console.log("Sending POST /api/ship with:", selected);
      await axios.post("/api/ship", selected);
      alert("Shipped successfully!");
    } catch (error) {
      console.error("AXIOS ERROR", error);
      if (error.response) {
        alert("Server responded with error: " + error.response.status);
      } else if (error.request) {
        alert("No response received. Network or proxy issue.");
      } else {
        alert("Request error: " + error.message);
      }
    }
  };

  return (
    <div
      style={{
        maxWidth: "900px",
        margin: "40px auto",
        padding: "20px",
        fontFamily: "Arial",
        color: "#003366",
      }}
    >
      <h1 style={{ fontSize: "32px", fontWeight: "bold", marginBottom: "20px" }}>
        Upload CSV & Ship Locations
      </h1>
  
      <input
        type="file"
        accept=".csv"
        onChange={handleFileUpload}
        style={{
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "6px",
          marginBottom: "20px",
          display: "block",
        }}
      />
  
      <input
        type="text"
        placeholder="Search locations"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "6px",
          width: "100%",
          maxWidth: "400px",
          marginBottom: "20px",
        }}
      />
  
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginBottom: "20px",
        }}
      >
        <thead>
          <tr>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>Select</th>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>Location</th>
          </tr>
        </thead>
        <tbody>
          {paginated.map((loc, i) => (
            <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "#f8f8f8" : "#fff" }}>
              <td style={{ border: "1px solid #ccc", padding: "10px", textAlign: "center" }}>
                <input
                  type="checkbox"
                  checked={selected.includes(loc)}
                  onChange={() => toggleSelect(loc)}
                />
              </td>
              <td style={{ border: "1px solid #ccc", padding: "10px" }}>{loc}</td>
            </tr>
          ))}
        </tbody>
      </table>
  
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          marginBottom: "30px",
        }}
      >
        <button
          onClick={() => setPage(Math.max(page - 1, 0))}
          style={{
            backgroundColor: "#007BFF",
            color: "white",
            padding: "8px 16px",
            border: "none",
            borderRadius: "6px",
            cursor: "pointer",
          }}
        >
          Previous
        </button>
  
        <span style={{ fontSize: "16px" }}>
          Page {page + 1} of {Math.ceil(filtered.length / perPage)}
        </span>
  
        <button
          onClick={() =>
            setPage(page + 1 < Math.ceil(filtered.length / perPage) ? page + 1 : page)
          }
          style={{
            backgroundColor: "#007BFF",
            color: "white",
            padding: "8px 16px",
            border: "none",
            borderRadius: "6px",
            cursor: "pointer",
          }}
        >
          Next
        </button>
      </div>
  
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          marginTop: "30px",
        }}
      >
        <button
          onClick={handleLogin}
          style={{
            backgroundColor: "#007BFF",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "6px",
            cursor: "pointer",
          }}
        >
          Login
        </button>
  
        <button
          onClick={handleShip}
          style={{
            backgroundColor: "#cc0000",
            color: "white",
            padding: "10px 30px",
            border: "none",
            borderRadius: "6px",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          Ship now
        </button>
  
        <button
          onClick={handleDeselectAll}
          style={{
            backgroundColor: "#007BFF",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "6px",
            cursor: "pointer",
          }}
        >
          Deselect All
        </button>
      </div>
    </div>
  );
  
}

export default App;
